import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../components/button/button";
import { SignInOrUp, SignUpInModal } from "../../components/signin_up_modal/signin_up_modal";
import { LenderDesiredLoanDetails, saveLenderDesiredLoanDetails } from "../../helpers/api";
import { getUserCookieData, LenderLoanCriteria, saveUserData } from "../../helpers/user";
import styles from "./lender_apply.module.css"
import React, { useEffect, useState } from 'react';
import CriteriaManagement from "../lender/criteria_management/criteria_management";
import { generateFingerprint } from "../../helpers/fingerprint";
import { useLoadedAnalytics } from "../../helpers/analytics";
import { LoanCalc } from "../../helpers/loan";

const LenderApplyPage = () => {
    const navigate = useNavigate();
    useLoadedAnalytics("lender_apply");
    const [isLoading, setIsLoading] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [canNext, setCanNext] = useState(false);
    const [criteria, setCriteria] = useState<LenderLoanCriteria[]>([
        {
            minimumRiskRating: 0,
            maximumRiskRating: 850,
            cashLendable: 50 * LoanCalc.ScaleFactor,
            minimumTerm: 1,
            maximumTerm: 24,
            portionWillingToFund: 100 * LoanCalc.ScaleFactor
        }
    ])

    const [lenderDetails, setLenderDetails] = useState<LenderDesiredLoanDetails>({
        Id: "",
        UserId: "",
        EmailAddr: "",
        Fingerprint: "",
        EstAt: new Date(),
        LoanCriteria: criteria
    });

    useEffect(()=>{
        if(criteria.length > 0) {
            setCanNext(true);
        } else {
            setCanNext(false);
        }
    }, [criteria.length])

    const onNextClick = async () => {
        setIsLoading(true);
        let tmp = lenderDetails;
        tmp.Fingerprint = await generateFingerprint();
        // save criteria
        tmp.LoanCriteria = criteria;
        tmp = await saveLenderDesiredLoanDetails(tmp)
        setLenderDetails(tmp);
        // sign user up/in and then take them to lenders page
        setShowSignInModal(true);
        setIsLoading(false);
    }

    const signInComplete = () => {
        setIsLoading(true);
        let user = getUserCookieData()!
        let tmp = lenderDetails
        tmp.LoanCriteria = criteria;
        user.loanData.loanCriteria = tmp.LoanCriteria;
        // get user ID, email, update criteria on backend
        tmp.EmailAddr = user.email;
        tmp.UserId = user.id;
        saveLenderDesiredLoanDetails(tmp);
        // also save in user data
        saveUserData(user);
        setIsLoading(false);
        navigate('/lender');
    }

    return (
        <div className={styles.Container}>
            <SignUpInModal showModal={showSignInModal} processCompleteHook={signInComplete} openIn={SignInOrUp.signUp}/>
            <h1>Enter Criteria For Borrowers You'd Like To Fund</h1>
            <CriteriaManagement initialCriteria={criteria} onCriteriaChange={setCriteria} secondaryButton={
                <ButtonPrimary onClick={onNextClick} disabled={!canNext || isLoading}>{isLoading ?  ". . ." : "Next"}</ButtonPrimary>
            }/>
        </div>
    );
}

export default LenderApplyPage;