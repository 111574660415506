import React, { useEffect, useRef, useState } from "react"
import styles from "./input.module.css"

export const InputCTA = (props: JSX.IntrinsicElements["input"]) => {
    const { children, ...rest } = props;
    return (
        <input className={`${styles.Input} ${styles.CTA} ${styles.CTAHover}`} {...rest}>{children}</input>
    );
}

export const InputPrimary = (props: JSX.IntrinsicElements["input"]) => {
    const { children, ...rest } = props;
    return (
        <input className={`${styles.Input} ${styles.Primary} ${styles.PrimaryHover}`} {...rest}>{children}</input>
    );
}

export const InputSecondary = (props: JSX.IntrinsicElements["input"]) => {
    const { children, ...rest } = props;
    return (
        <input className={`${styles.Input} ${styles.Secondary} ${styles.SecondaryHover}`} {...rest}>{children}</input>
    );
}

export const Checkbox = (props: JSX.IntrinsicElements["input"]) => {
    const { children, ...rest } = props;
    return (
        <input type='checkbox' className={`${styles.Checkbox}`} {...rest}>{children}</input>
    );
}

// Define the props interface
interface RangeSliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
    min: number;
    max: number;
    step?: number;
    stepValues?: any[];
    showLabel?: boolean;
    onChange?: (value: any) => void;
    controlledValue?: number;
    initialValue?: number;
}

export const RangeSlider = ({ min, max, step = 1, stepValues, showLabel, onChange, controlledValue, initialValue, ...rest }: RangeSliderProps) => {
    const [internalIndex, setInternalIndex] = useState<number>(initialValue !== undefined ? initialValue : 0);
    const [isControlled, setIsControlled] = useState<boolean>(controlledValue !== undefined); 
    const sliderRef = useRef<HTMLInputElement>(null); 
    const [thumbOffset, setThumbOffset] = useState<number>(0);

    // Update internal index if controlledValue changes
    useEffect(() => {
        if (controlledValue !== undefined) {
            setInternalIndex(controlledValue);
            setIsControlled(true);
        }
    }, [controlledValue, stepValues]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newIndex = parseInt(e.target.value, 10);
        // If stepValues are provided, use the index to select the value
        if (stepValues && stepValues.length > 0) {
            if (newIndex >= 0 && newIndex < stepValues.length) {
                const selectedValue = stepValues[newIndex];
                setInternalIndex(newIndex);
                // Fire onChange callback with the actual value at the selected index
                if (onChange) {
                    onChange(selectedValue);
                }
                // Update thumb position for the slider
                updateThumbPosition(e.target, newIndex, stepValues.length - 1);
            }
        } else {
            // Normal behavior: Use the value directly
            const newValue = parseFloat(e.target.value);
            if (!isControlled) {
                setInternalIndex(newValue);
            }
            if (onChange) {
                onChange(e);
            }
            updateThumbPosition(e.target, newValue, max);
        }
    };

    const updateThumbPosition = (inputElement: HTMLInputElement, value: number, maxValue: number) => {
        const percentage = ((value - 0) / (maxValue - 0)) * 100;
        setThumbOffset(percentage);
    };

    useEffect(() => {
        if (sliderRef.current) {
            updateThumbPosition(sliderRef.current, internalIndex, stepValues ? stepValues.length - 1 : max);
        }
    }, [internalIndex, max, stepValues]);

    return (
        <div className={styles.RangeContainer}>
            {showLabel ? <div 
                className={styles.RangeLabel}
                style={{ left: `calc(${thumbOffset}%)` }} // Dynamically update position
            >
                {stepValues && stepValues.length > 0 ? stepValues[internalIndex] : internalIndex}
            </div> : null}
            <input
                ref={sliderRef}
                type="range"
                min={min}
                max={stepValues && stepValues.length > 0 ? stepValues.length - 1 : max}
                step={stepValues ? 1 : step} // Step by index when using stepValues, else use the normal step value
                value={internalIndex}
                onChange={handleChange}
                className={styles.RangeSlider}
                {...rest}
            />
        </div>
    );
};

interface DualRangeSliderProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    min: number;
    max: number;
    step?: number;
    stepValues?: any[];
    showLabel?: boolean;
    onChange?: (minValue: number, maxValue: number) => void; // Custom onChange
    initialMinValue?: number;
    initialMaxValue?: number;
}

export const DualRangeSlider = ({min, max, step = 1, stepValues, showLabel, onChange, initialMinValue = min, initialMaxValue = max, ...rest}: DualRangeSliderProps) => {
    const [minValue, setMinValue] = useState<number>(initialMinValue);
    const [maxValue, setMaxValue] = useState<number>(initialMaxValue);
    const [minThumbOffset, setMinThumbOffset] = useState<number>(0);
    const [maxThumbOffset, setMaxThumbOffset] = useState<number>(100);
    const minSliderRef = useRef<HTMLInputElement>(null);
    const maxSliderRef = useRef<HTMLInputElement>(null);

    // Update thumb position based on value changes
    useEffect(() => {
        updateThumbPosition(minSliderRef.current, minValue, maxValue, max, "min");
        updateThumbPosition(maxSliderRef.current, maxValue, minValue, max, "max");
    }, [minValue, maxValue, max]);

    const updateThumbPosition = (
        inputElement: HTMLInputElement | null,
        value: number,
        otherValue: number,
        maxValue: number,
        type: "min" | "max"
    ) => {
        const percentage = ((value - min) / (maxValue - min)) * 100;
        if (type === "min") setMinThumbOffset(percentage);
        if (type === "max") setMaxThumbOffset(percentage);
    };

    // Handle min slider change
    const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newMinValue = parseInt(e.target.value, 10);

        if (newMinValue > maxValue) {
            // If minValue exceeds maxValue, switch context and update maxValue
            setMaxValue(newMinValue);
            if (onChange) onChange(newMinValue, newMinValue);
        } else {
            setMinValue(newMinValue);
            if (onChange) onChange(newMinValue, maxValue);
        }
    };

    // Handle max slider change
    const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newMaxValue = parseInt(e.target.value, 10);

        if (newMaxValue < minValue) {
            // If maxValue goes below minValue, switch context and update minValue
            setMinValue(newMaxValue);
            if (onChange) onChange(newMaxValue, newMaxValue);
        } else {
            setMaxValue(newMaxValue);
            if (onChange) onChange(minValue, newMaxValue);
        }
    };

    return (
        <div className={styles.DualRangeContainer}>
            {showLabel && (
                <>
                    <div
                        className={styles.DualRangeLabel}
                        style={{ left: `calc(${minThumbOffset}%)` }}
                    >
                        {minValue}
                    </div>
                    <div
                        className={styles.DualRangeLabel}
                        style={{ left: `calc(${maxThumbOffset}%)` }}
                    >
                        {maxValue}
                    </div>
                </>
            )}
            <div
                className={styles.RangeTrack}
                style={{
                    background: `linear-gradient(to right, transparent ${minThumbOffset}%, var(--text-color-3) ${minThumbOffset}%, var(--highlight-color) ${maxThumbOffset}%, transparent ${maxThumbOffset}%)`,
                }}
            />
            <input
                ref={minSliderRef}
                type="range"
                min={min}
                max={max}
                step={step}
                value={minValue}
                onChange={handleMinChange}
                className={styles.DualRangeSlider}
                {...rest}
            />
            <input
                ref={maxSliderRef}
                type="range"
                min={min}
                max={max}
                step={step}
                value={maxValue}
                onChange={handleMaxChange}
                className={styles.DualRangeSlider}
                {...rest}
            />
        </div>
    );
};