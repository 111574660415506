export interface LoanPaymentData {
	principal: number
	interest:  number
	date:      string
}

export interface Loan {
    id:                    string
	loanTotalInCents:      number              //total amount of loan, excludes interest
	loanAmountInCents:     number              //the amount of the loan, excluding origination
	loanPaidInCents:       number              //how much of the total loan has been paid, excludes interest
	originationFeeInCents: number              //origination fee as a part of the loan amount
	expectedInterestInCents:number             //how much interest will be paid on the loan
	loanDurationInDays:    number              //how many days this loan is expected to be open
	loanInterestRate:      number              //kept as an int scaled by helpers.ScaleFactor to avoid precision errors
	loanTerm:              number              //number of months in loan term
	loanStartDate:         string              //when this loan was opened
	loanEndDate:           string              //when this loan is expted to end
	portion:               number              //portion of loan funded by lender
	expectedPayments:      LoanPaymentData[]   //expected payments over term of loan
	actualPayments:        LoanPaymentData[]   //actual payments made over term of loan
	isActive:              boolean             //true if the loan was accepted, false if not
	isComplete:            boolean             //true if the loan is payed off
	isDefault:             boolean             //true if this loan is in default
	isForSale:             boolean             //true if this loan is for sale
	defaultAmout:          number              //the amount that went unpaid
}

export class LoanCalc {
	static ScaleFactor = 1000;
	// PMT calculates the payment for a loan based on constant payments and a constant interest rate.
	// rate is the interest rate per period (scaled by ScaleFactor), nper is the number of periods, pv (scaled by ScaleFactor) is the present value (loan amount).
	static PMT(rate: number, nper: number, pv: number): number {
		const r = rate / LoanCalc.ScaleFactor / 100;
		const pvF = pv / LoanCalc.ScaleFactor;
		const pmt = (pvF * r) / (1 - Math.pow(1 + r, -nper));
		return Math.round(pmt * LoanCalc.ScaleFactor);
	}
  
	// IPMT calculates the interest payment for a given period of a loan.
	// rate is the interest rate per period (scaled by ScaleFactor), per is the period for which the interest is calculated, nper is the number of periods, pv (scaled by ScaleFactor) is the present value (loan amount).
	static IPMT(rate: number, per: number, nper: number, pv: number): number {
		const r = rate / LoanCalc.ScaleFactor / 100;
		const pvF = pv / LoanCalc.ScaleFactor;
		const pmt = LoanCalc.PMT(rate, nper, pv) / LoanCalc.ScaleFactor;
		const remainingBalance = pvF * Math.pow(1 + r, per - 1) - (pmt * (Math.pow(1 + r, per - 1) - 1)) / r;
		const ipmt = remainingBalance * r;
		return Math.round(ipmt * LoanCalc.ScaleFactor);
	}
  
	// CUMIPMT calculates the cumulative interest paid on a loan between two periods.
	// rate is the interest rate per period (scaled by ScaleFactor), nper is the number of periods, pv (scaled by ScaleFactor) is the present value (loan amount), startPeriod is the starting period, endPeriod is the ending period.
	static CUMIPMT(rate: number, nper: number, pv: number, startPeriod: number, endPeriod: number): number {
		let totalInterest = 0;
		for (let per = startPeriod; per <= endPeriod; per++) {
			totalInterest += LoanCalc.IPMT(rate, per, nper, pv);
		}
		return totalInterest;
	}
  
	// PPMTArray calculates the principal payment for each period of a loan.
	// rate is the interest rate per period (scaled by ScaleFactor), nper is the number of periods, pv (scaled by ScaleFactor) is the present value (loan amount).
	static PPMTArray(rate: number, nper: number, pv: number): number[] {
		const pmt = LoanCalc.PMT(rate, nper, pv);
		const ppmtArray: number[] = new Array(nper).fill(0);
		let remainingBalance = pv / LoanCalc.ScaleFactor;
		for (let per = 1; per <= nper; per++) {
			const ipmt = LoanCalc.IPMT(rate, per, nper, pv) / LoanCalc.ScaleFactor;
			const ppmt = pmt / LoanCalc.ScaleFactor - ipmt;
			ppmtArray[per - 1] = Math.round(ppmt * LoanCalc.ScaleFactor);
			remainingBalance -= ppmt;
		}
		return ppmtArray;
	}
  
	// IPMTArray calculates the interest payment for each period of a loan.
	// rate is the interest rate per period (scaled by ScaleFactor), nper is the number of periods, pv (scaled by ScaleFactor) is the present value (loan amount).
	static IPMTArray(rate: number, nper: number, pv: number): number[] {
		const ipmtArray: number[] = new Array(nper).fill(0);
		let remainingBalance = pv / LoanCalc.ScaleFactor;
		for (let per = 1; per <= nper; per++) {
			const ipmt = remainingBalance * (rate / LoanCalc.ScaleFactor / 100);
			ipmtArray[per - 1] = Math.round(ipmt * LoanCalc.ScaleFactor);
			const ppmt = LoanCalc.PMT(rate, nper, pv) / LoanCalc.ScaleFactor - ipmt;
			remainingBalance -= ppmt;
		}
		return ipmtArray;
	}
}
  
