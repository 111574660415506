import styles from './criteria_view.module.css';
import React, { useEffect, useState } from 'react';
import ToolTipIcon from '../../../components/tooltip_icon/tooltip_icon';
import { LenderLoanCriteria } from '../../../helpers/user';
import { InputPrimary, RangeSlider } from '../../../components/input/input';
import { LoanCalc } from '../../../helpers/loan';
import { EstBorrowersAndyieldsResponse, estimateBorrowersAndyieldsGivenCriteria } from '../../../helpers/api';

interface CriteriaViewProps {
    criteria: LenderLoanCriteria;
    idx: number;
    onCriteriaChangeHandler: (e: React.ChangeEvent<HTMLInputElement>, idx: number)=>void;
    onCriteriaBlur: (e: React.FocusEvent<HTMLInputElement, Element>, idx: number)=>void;
    RemLoanCriteriaHandler: (idx: number)=>void;
}

const LendableView = ({criteria, idx, onCriteriaChangeHandler, onCriteriaBlur}: CriteriaViewProps) => {
    return (
        <div className={styles.ViewContainer}>
            <div className={styles.LabelContainer}>
                <h2>Capital <span>($USD)</span></h2>
                <div className={styles.TooltipContainer}>
                    <ToolTipIcon>
                        <div style={{width: 'fit-content', textAlign: 'left', margin: 'auto'}}>
                            <p>Maximum capital to lend to borrowers matching this criteria.</p>
                            <p>Minimum of $50. Values under 50, will be set to 50.</p>
                            <br/>
                            <p>Portion of Cash Lendable put into any one loan.</p>
                            <p>A maximum of ${((criteria.cashLendable * criteria.portionWillingToFund) / 100_000_000).toFixed(0)} goes into one loan.</p>
                            <p>The remaining amount will be split into other loans.</p>
                            {/* <p>Or ${((criteria.cashLendable * criteria.portionWillingToFund) / 100_000_000).toFixed(0)} into {Math.floor((criteria.cashLendable/1000) / ((criteria.cashLendable * criteria.portionWillingToFund) / 100_000_000))} equal loans</p> */}
                            <p>The lower this value, the greater the distribution of risk.</p>
                            <p>You may still fund loans at {((criteria.cashLendable * criteria.portionWillingToFund) / 100_000_000) > 50 ? "or under ": ""}${((criteria.cashLendable * criteria.portionWillingToFund) / 100_000_000).toFixed(0)}</p>
                            <p>but will not fund loans over ${((criteria.cashLendable * criteria.portionWillingToFund) / 100_000_000).toFixed(0)}</p>
                        </div>
                    </ToolTipIcon>
                </div>
            </div>
            <h3>Lendable</h3>
            <InputPrimary 
                name='cashLendable'
                type='text'
                value={criteria.cashLendable / LoanCalc.ScaleFactor}
                onChange={(e)=>{onCriteriaChangeHandler(e, idx)}}
                onBlur={(e)=>onCriteriaBlur(e, idx)}
            />
            <h3><span>{(criteria.portionWillingToFund/1000).toFixed(0)}%</span> per loan <span>(${((criteria.cashLendable * criteria.portionWillingToFund) / 100_000_000).toFixed(0)})</span></h3>
            <RangeSlider name='portionWillingToFund' 
                min={1} 
                max={100}
                step={1} 
                showLabel={false}
                initialValue={criteria.portionWillingToFund / LoanCalc.ScaleFactor}
                controlledValue={criteria.portionWillingToFund / LoanCalc.ScaleFactor}
                onChange={(e)=>{onCriteriaChangeHandler(e, idx)}}
            />
        </div>
    );
}

const CreditScoreView = ({criteria, idx, onCriteriaChangeHandler}: CriteriaViewProps) => {

    const anticipatedDefaultRate = (score: number): string => {
        if (score >= 720) return "<2%";
        if (score >= 680) return "~3.5%";
        if (score >= 660) return "~4.5%";
        if (score >= 640) return "~5.5%";
        if (score >= 620) return "~7.5%";
        if (score >= 580) return "~9.5%";
        if (score >= 560) return "~12.5%";
        return "~15+%";
    }

    return (
        <div className={styles.ViewContainer}>
            <div className={styles.LabelContainer}>
                <h2>Credit Score</h2>
                <div className={styles.TooltipContainer}>
                    <ToolTipIcon>
                        <div style={{width: 'fit-content', textAlign: 'left', margin: 'auto'}}>
                            <h4>Anticipated Default Rate: {anticipatedDefaultRate((criteria.minimumRiskRating + criteria.maximumRiskRating)/2)}</h4>
                            <p>Defaults are estimated based on credit range</p>
                            <p>and not accurate in practice.</p>
                            <p>A higher value means less risk.</p>
                        </div>
                    </ToolTipIcon>
                </div>
            </div>
            <h3>Min: <span>{criteria.minimumRiskRating}</span></h3>
            <RangeSlider name='minimumRiskRating' 
                min={0} 
                max={850}
                step={10} 
                showLabel={false}
                initialValue={criteria.minimumRiskRating}
                controlledValue={criteria.minimumRiskRating}
                onChange={(e)=>{onCriteriaChangeHandler(e, idx)}}
            />
            <h3>Max: <span>{criteria.maximumRiskRating}</span></h3>
            <RangeSlider name='maximumRiskRating' 
                min={0} 
                max={850}
                step={10} 
                showLabel={false}
                initialValue={criteria.maximumRiskRating}
                controlledValue={criteria.maximumRiskRating}
                onChange={(e)=>{onCriteriaChangeHandler(e, idx)}}
            />
        </div>
    );
}

const TermView = ({criteria, idx, onCriteriaChangeHandler}: CriteriaViewProps) => {
    return (
        <div className={styles.ViewContainer}> 
            <div className={styles.LabelContainer}>
                <h2>Term</h2>
                <ToolTipIcon>
                    <div style={{width: 'fit-content', textAlign: 'left', margin: 'auto'}}>
                        <p>Minimum and maximum number of month you are willing to fund a loan.</p>
                        <p>You'd expect full repayment in {criteria.minimumTerm} to {criteria.maximumTerm} months.</p>
                        <p>A borrower may still default or pay off their loan early in these time frames.</p>
                    </div>
                </ToolTipIcon>
            </div>
            <h3>Min: <span>{criteria.minimumTerm}</span> {criteria.minimumTerm === 1 ? "Month" : "Months"}</h3>
            <RangeSlider name='minimumTerm' min={1} max={24} initialValue={criteria.minimumTerm} controlledValue={criteria.minimumTerm} step={1} showLabel={false} onChange={(e)=>{onCriteriaChangeHandler(e, idx)}}/>
            <h3>Max: <span>{criteria.maximumTerm}</span> {criteria.maximumTerm === 1 ? "Month" : "Months"}</h3>
            <RangeSlider name='maximumTerm' min={1} max={24} initialValue={criteria.maximumTerm} controlledValue={criteria.maximumTerm} step={1} showLabel={false} onChange={(e)=>{onCriteriaChangeHandler(e, idx)}}/>
        </div>
    );
}

// TODO: add options for employment
// TODO: add options for debt/income
// TODO: add option for marketplace loans sold by other lenders
// TODO: filtering by month for borrowers
const CriteriaView = ({criteria, idx, onCriteriaChangeHandler, onCriteriaBlur, RemLoanCriteriaHandler}: CriteriaViewProps) => {

    const [est, setEst] = useState<EstBorrowersAndyieldsResponse>({
        EstBorrowers: 0,
        Minyield: 0,
        Maxyield: 0
    });

    useEffect(()=>{
        // debouce
        const timer = setTimeout(() => {
            estimateBorrowersAndyieldsGivenCriteria(criteria).then((resp)=>{
                setEst(resp);
            })
        }, 1250);

        // Cleanup the timeout if criteria changes before the 2 seconds
        return () => clearTimeout(timer);
    }, [criteria.maximumRiskRating, criteria.minimumRiskRating])

    return (
        <div className={styles.CriteriaContainer}>
            <div className={styles.CriteriaHeading}>
                <h2 style={{color: `var(--accent-color-${(idx % 17) + 1}`}}>Criteria {idx + 1}</h2>
                <button  onClick={()=>{RemLoanCriteriaHandler(idx)}}>❌</button>
            </div>
            <div className={styles.CriteriaHeading}>
                <h3>~{est?.EstBorrowers} borrowers<span style={{verticalAlign: "super", fontSize: "smaller"}}>?</span></h3>
                <ToolTipIcon>
                    <div style={{width: 'fit-content', textAlign: 'left', margin: 'auto'}}>
                        <p>An estimate (based on 2023 Prosper Listings) of how many potential borrowers match</p>
                        <p>this criteria. The higher the number the more likely you are to fund a loan</p>
                        <p>to someone with these criteria.</p>
                        <br/>
                        <p>Consider that borrowers are likely seeking lower monthly payments, which</p>
                        <p>typically occurs at longer term lengths or shorter for those with higher scores.</p>
                    </div>
                </ToolTipIcon>
            </div>
            <div className={styles.CriteriaHeading}>
                <h3>~{(est?.Minyield/LoanCalc.ScaleFactor)-1}-{(est?.Maxyield/LoanCalc.ScaleFactor)-1}% Yields<span style={{verticalAlign: "super", fontSize: "smaller"}}>?</span></h3>
                <ToolTipIcon>
                    <div style={{width: 'fit-content', textAlign: 'left', margin: 'auto'}}>
                        <p>Yields displayed are annualized and estimated based on historical data and are not guaranteed.</p>
                        <p>Actual returns may vary and are subject to risks including borrower defaults and early repayments.</p>
                        <p>Your investment may earn less than projected or result in a complete loss of investment.</p>
                        <p>Please consider the risks and perform due diligence before funding loans in this, or any critera.</p>
                    </div>
                </ToolTipIcon>
            </div>
            <div key={idx} className={styles.LoanCriteriaSettingsContainer}>
                <LendableView criteria={criteria} idx={idx} onCriteriaChangeHandler={onCriteriaChangeHandler} onCriteriaBlur={onCriteriaBlur} RemLoanCriteriaHandler={RemLoanCriteriaHandler} />
                <CreditScoreView criteria={criteria} idx={idx} onCriteriaChangeHandler={onCriteriaChangeHandler} onCriteriaBlur={onCriteriaBlur} RemLoanCriteriaHandler={RemLoanCriteriaHandler} />
                <TermView criteria={criteria} idx={idx} onCriteriaChangeHandler={onCriteriaChangeHandler} onCriteriaBlur={onCriteriaBlur} RemLoanCriteriaHandler={RemLoanCriteriaHandler}/>
            </div>
        </div>
    );
}

export default CriteriaView;