import './loan_application.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';

// import UtilizationChart from '../../components/utilization_chart/charts.tsx'
import { borrowerAccetpedLoanSetActive, borrowerCreateLoan, borrowerDeclinedDeleteLoan, borrowerDesiredLoanDetails, BorrowerEstimatedLoanDetails } from '../../helpers/api.ts';
import LoanEstimateForm, { LOAN_DETAIL_CONSTS } from './application/desired_loan_form/desiredLoanForm.tsx';
import PersonalDetailsForm, { PersonalDetailsData } from './application/personalDetailsForm.tsx';
import ProgressBar from '../../components/progress_bar/progress_bar.tsx';
import BankLink from './application/bankLink/bankLink.tsx';
import VerifyIdentity from './application/verifyIdentity.tsx';
import LoanTermsAgreement from './application/LoanAgreement/loanAgreement.tsx';
import { User, getUserCookieData, isUserSignedIn, saveUserData } from '../../helpers/user.ts';
import { SignInOrUp, SignUpInModal } from '../../components/signin_up_modal/signin_up_modal.tsx';
import { useNavigate } from 'react-router-dom';
import { Loan } from '../../helpers/loan.ts';
import UnderwriteLoan from './application/UnderwritingLoan/underwritingLoan.tsx';
import WaitlistMessage from './application/waitlist_message/waitlist_message.tsx';
import { useLoadedAnalytics } from '../../helpers/analytics.tsx';

// would probably want to use this to fill out availability map next time
// https://jspaint.app/#local:11cfbebff512d8

enum APPLICATION_STEP {
    loanInfo,
    // estimateView,
    promptLoginSignup,
    waitlistMessage,
    // personalInfo,
    // verifyIdentity,
    // linkBank,
    // underwriteLoan,
    // finalizedDetails,
    // loanConfirmation,
    MAX
}

const LoanApplication = () => {
    var previousApplicationStep = useRef(APPLICATION_STEP.loanInfo);
    useLoadedAnalytics("loan_application");
    const navigate = useNavigate();
    const [applicationProgress, setApplicationProgress] = useState(APPLICATION_STEP.loanInfo);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const applicationProgressControl = useCallback((dir: boolean) => {
        let count = applicationProgress;
        let didMove = false;
        if(dir) {
            // if count + 1 is less than APPLICATION_STEPS.MAX increment the step
            if(count + 1 <= APPLICATION_STEP.MAX) {
                previousApplicationStep.current = count;
                count++;
                didMove = true;
            }
        } else {
            // if count - 1 is greater than 0 decrement the step
            if(count - 1 > -1) {
                previousApplicationStep.current = count;
                count--;
                didMove = true;
            }
        }
        setApplicationProgress(count);
        if(didMove) {
            window.scrollTo(0, 0);
        }
    }, [applicationProgress])

    useEffect(()=>{
        window.scrollTo(0, 0);
        // check if user is logged in, if so, continue to next step, else prompt login/signup
        if(applicationProgress >= APPLICATION_STEP.promptLoginSignup) {
            const isSignedIn = isUserSignedIn();
            if(!isSignedIn) {
                setShowLoginModal(true);
                // also kick the user back to loan view details as some info may have changed, also resetting relevant structs in this page
                setApplicationProgress(APPLICATION_STEP.loanInfo);
            } else {
                let skip = false;
                skip = skip || (applicationProgress === APPLICATION_STEP.promptLoginSignup);
                // skip = skip || (applicationProgress === APPLICATION_STEP.linkBank && currentUserData!.isPlaidAccess);
                // skip = skip || (applicationProgress === APPLICATION_STEP.verifyIdentity && currentUserData!.isIdentityVerified);
                if(skip) {
                    // going forward
                    if(previousApplicationStep.current < applicationProgress) {
                        applicationProgressControl(true);
                    } else {
                        // going backward
                        applicationProgressControl(false);
                    }
                }
            }
        }
    }, [applicationProgress, applicationProgressControl, previousApplicationStep])

    const loginSignUpComplete = () => {
        const isSignedIn = isUserSignedIn();
        if(isSignedIn) {
            applicationProgressControl(true);
            setShowLoginModal(false);
            // update details to link it to user
            let tmp = desiredLoanDetails;
            tmp.EmailAddr = getUserCookieData()!.email
            tmp.UserId = getUserCookieData()!.id
            setDesiredLoanDetails(tmp); //add's user specific info
            borrowerDesiredLoanDetails(tmp);
        }
    }

    const [desiredLoanDetails, setDesiredLoanDetails] = useState<BorrowerEstimatedLoanDetails>({
        Id: "",
        EmailAddr: getUserCookieData()?.email || "",
        UserId: getUserCookieData()?.id || "",
        EstAt: new Date(),
        Purpose: "",
        EstCredit: 720,
        Amount: LOAN_DETAIL_CONSTS.MIN_LOANABLE,
        LoanDetails: {
            Term: LOAN_DETAIL_CONSTS.MIN_LOAN_TERM,
            MonthlyPayment: 0,
            TotalInterest: 0,
            InterestPayments: [],
            PrincipalPayments: [],
            OriginationFee: 0,
            Apy: 0,
            Apr: 0
        }
    });

    const [personalDetailsData, setPersonalDetailsData] = useState<PersonalDetailsData>({
        firstName: getUserCookieData()?.bio.firstName || "",
        lastName: getUserCookieData()?.bio.lastName || "",
        dob: getUserCookieData()?.bio.dob || "",
        phone: getUserCookieData()?.bio.phone || "",
        employmentStatus: getUserCookieData()?.bio.employmentStatus || "",
        income:  getUserCookieData()?.bio.incomeBeforeTaxes || 0,
        zipCode: getUserCookieData()?.bio.zip || "",
        state: getUserCookieData()?.bio.state || "",
        address: getUserCookieData()?.bio.address || "",
        residentType: getUserCookieData()?.bio.typeOfResidence || "",
    });

    const [finalizedLoanDetails, setFinalizedLoanDetails] = useState<Loan>({
        id: "",
        loanTotalInCents: 0,
        loanAmountInCents: 0,
        loanPaidInCents: 0,
        originationFeeInCents: 0,
        expectedInterestInCents: 0,
        loanDurationInDays: 0,
        loanInterestRate: 0,
        loanTerm: 0,
        loanStartDate: "",
        loanEndDate: "",
        portion: 0,
        expectedPayments: [],
        actualPayments: [],
        isActive: false,
        isComplete: false,
        isDefault: false,
        isForSale: false,
        defaultAmout: 0,
    });

    // called to provide loan estimation details and continue to the next step
    const loanDetailsCB = (_desiredLoanDetails: BorrowerEstimatedLoanDetails) => {
        setDesiredLoanDetails(_desiredLoanDetails);
        applicationProgressControl(true);
    }

    const personalDetailsCb = (_personalDetailsData: PersonalDetailsData) => {
        let updatedUser: User | null = getUserCookieData()!;
        updatedUser.bio.firstName = _personalDetailsData.firstName;
        updatedUser.bio.lastName = _personalDetailsData.lastName;
        updatedUser.bio.dob = _personalDetailsData.dob;
        updatedUser.bio.phone = _personalDetailsData.phone;
        updatedUser.bio.zip = _personalDetailsData.zipCode;
        updatedUser.bio.state = _personalDetailsData.state;
        updatedUser.bio.address = _personalDetailsData.address;
        updatedUser.bio.employmentStatus = _personalDetailsData.employmentStatus
        updatedUser.bio.incomeBeforeTaxes = _personalDetailsData.income
        updatedUser.bio.typeOfResidence = _personalDetailsData.residentType
        saveUserData(updatedUser);
        setPersonalDetailsData(_personalDetailsData);
        applicationProgressControl(true);
    }

    const finalizedLoanCB = (_finalizedLoan: Loan) => {
        setFinalizedLoanDetails(_finalizedLoan);
        incProgressCb();
    }

    // delete the loan and move user back to HOME, delete the loan that was delined in finalizedLoan
    const loanDeclineCB = () => {
        borrowerDeclinedDeleteLoan(finalizedLoanDetails.id)
        navigate("/");
    }

    const loanAcceptedCB = () => {
        borrowerAccetpedLoanSetActive(finalizedLoanDetails.id)
        navigate("/manage_loan");
    }

    // called to go back one step in the application process
    const decProgressCb = () => {
        applicationProgressControl(false);
    }

    const incProgressCb = () => {
        applicationProgressControl(true);
    }

    return (
        <div className='BorrowHomeContainer'>
            {/* <div className='AvailabilityContainer'>
                <h3 className="InputTitle">State Availability</h3>
                <img src='assets/borrower_home/states_of_operation copy.webp' className='AvailabilityMap' alt='availability map'/>
            </div> */}
            {showLoginModal ? <SignUpInModal showModal={showLoginModal} modalEscapeCB={undefined} processCompleteHook={loginSignUpComplete} openIn={SignInOrUp.signUp}/> : 
                <>
                    <div className='ApplicationProgressBar'>
                        <h2 className='InputTitle'>Application Progress</h2>
                        <ProgressBar progress={applicationProgress+1} max={APPLICATION_STEP.MAX}/>
                    </div>
                    {applicationProgress === APPLICATION_STEP.loanInfo ? <LoanEstimateForm estLoanDetails={desiredLoanDetails} incProgressCb={loanDetailsCB} /> : null}
                    {/* force login after here */}
                    {applicationProgress === APPLICATION_STEP.waitlistMessage ? <WaitlistMessage /> : null}
                    {/* {applicationProgress === APPLICATION_STEP.personalInfo ? <PersonalDetailsForm personalDetailsData={personalDetailsData} incProgressCb={personalDetailsCb} decProgressCb={decProgressCb}/> : null} */}
                    {/* TODO: identity verification */}
                    {/* {applicationProgress === APPLICATION_STEP.verifyIdentity && !getUserCookieData()?.isIdentityVerified ? <VerifyIdentity incProgressCb={incProgressCb} decProgressCb={decProgressCb}/> : null} */}
                    {/* {applicationProgress === APPLICATION_STEP.linkBank ? <BankLink incProgressCb={incProgressCb} decProgressCb={decProgressCb}/> : null} */}
                    {/* underwriting step - could take a few seconds then will either result in a loan, or inabulity to fund loan */}
                    {/* {applicationProgress === APPLICATION_STEP.underwriteLoan ? <UnderwriteLoan loanAmount={desiredLoanDetails.Amount} loanTerm={desiredLoanDetails.Term} loanPurpose={desiredLoanDetails.Purpose} incProgressCb={finalizedLoanCB}/> : null} */}
                    {/* {applicationProgress === APPLICATION_STEP.finalizedDetails ? <LoanDetailsDisplay estimatedLoanDetails={null} finalLoanDetails={finalizedLoanDetails} incProgressCb={incProgressCb} decProgressCb={loanDeclineCB}/> : null} */}
                    {/* {applicationProgress === APPLICATION_STEP.loanConfirmation ? <LoanTermsAgreement finalizedLoan={finalizedLoanDetails} borrowerName={`${personalDetailsData.firstName} ${personalDetailsData.lastName}`} borrowerState={personalDetailsData.state} borrowerAddress={personalDetailsData.address} borrowerEmail={desiredLoanDetails.EmailAddr} incProgressCb={loanAcceptedCB} decProgressCb={loanDeclineCB}/> : null} */}
                </>
            }
        </div>
    )
}

export default LoanApplication;