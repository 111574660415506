import styles from './enable_dashboard.module.css';
import React, { useEffect, useRef, useState } from "react";
import SHA256 from 'crypto-js/sha256';
import { generateFingerprint } from '../../../helpers/fingerprint.ts';
import { getUserCookieData } from '../../../helpers/user.ts';
import { subscribeToService, unsubscribeFromService, checkSubscriptionService} from '../../../helpers/api.ts';
import LoadingIcon from '../../../components/loading_icon/loading_icon.tsx';
import ToggleSwitch from '../../../components/toggle_input/toggle_input.tsx';
import MyModal, { ConfirmationModal, ErrorMessageModal, MessageModal } from '../../../components/my_modal/my_modal.tsx';
import { useNavigate } from 'react-router-dom';
import { BackendPlaidResponse } from '../../../helpers/api.ts';

// TODO: return back to 1.67 when transfer is available
// const DASHBOARD_MONTHLY_PRICE = 1.67;
const DASHBOARD_MONTHLY_PRICE = 0.00;

export const EnableDashboard = () => {
    const navigate = useNavigate();
    const [showEnableModal, setShowEnableModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessageModalText, setErrorMessageModalText] = useState("");
    const [showPaymentErrorModal, setShowPaymentErrorModal] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [messageModalJSX, setMessageModalJSX] = useState(<></>);
    const [onLoad, setOnLoad] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [incomingIndex, setIncomingIndex] = useState(0);

    let userId = useRef("");

    const calcMonthlyDashboardCost = (): string => {
        const user = getUserCookieData();
        let cost = 0;
        if(user) {
            for (let i = 0; i < user.plaidLinkedBanks.length; i++) {
                cost += DASHBOARD_MONTHLY_PRICE * user.plaidLinkedBanks[i].accounts.length
            }
        }
        return cost.toFixed(2);
    }

    // on load, determine if finances are currently tracked. TODO:if user has a loan this is disabled or hidden
    useEffect(()=> {
        const userdata = getUserCookieData();
        if(userdata) {
            if(userdata.transactionDashboardEnabled) {
                setSelectedIndex(0);
            }
            userId.current = userdata.id;
            checkSubscriptionService(userdata.id, "FinancialDashboard").then((resp: BackendPlaidResponse)=>{
                console.log(resp)
                if(resp.status === 200) {
                    setSelectedIndex(0);
                } else {
                    setSelectedIndex(1);
                }
            })
        } 
    }, [])

    const toggled = (selectedOption: number) => {
        setIncomingIndex(selectedOption);
        if(selectedOption !== selectedIndex) {
            setShowEnableModal(true);
        }
    }

    const onReject = () => {
        setShowEnableModal(false);
        setSelectedIndex(1);
    }

    const onAccept = () => {
        // ensure user has name fields dilled out and at least one bank linked before trying to subscribe
        const userdata = getUserCookieData();
        if(!userdata) return;
        if(userdata.bio.firstName.length < 1 && userdata.bio.lastName.length < 1) {
            setMessageModalJSX(<p>Please enter your first and last name.<br/> This is used to validate payment information.</p>);
            setShowMessageModal(true);
            return;
        }
        if(userdata.plaidLinkedBanks.length < 1) {
            setMessageModalJSX(<p>Please link at least one account for payment and tracking.</p>);
            setShowMessageModal(true);
            return;
        }
        
        setOnLoad(true);
        // send to server to charge bank
        // on error, display error message
        // on success navigate to dashboard
        subscribeToService(userId.current, "FinancialDashboard").then(async (resp: BackendPlaidResponse)=>{
            if(resp.status === 200) {
                if (sessionStorage.getItem("utm_source") === 'TikTok' && getUserCookieData()) {
                    if (window.ttq) {
                        const fingerprint = await generateFingerprint();
                        const hashedEmail = SHA256(getUserCookieData()!.email).toString();
                        const hashedFingerprint = SHA256(fingerprint).toString();
                        window.ttq.identify({
                            "email": hashedEmail,
                            "external_id": hashedFingerprint
                        });
                        window.ttq.track('SubmitForm', {
                            "contents": [
                                {
                                    "content_id": "waitlist_submission",
                                    "content_name": "Waitlist Form"
                                }
                            ]
                        });
                    }
                }
                navigate("/dashboard");
            } else if(resp.status === 400) {
                // some server error
                setErrorMessageModalText(resp.err)
                setShowErrorModal(true);
            } else if(resp.status === 422) {
                // payment error
                setErrorMessageModalText(resp.err)
                setShowPaymentErrorModal(true);
            }
        }).finally(()=>{
            setOnLoad(false);
        });
    }

    const onUnsubscribe = () => {
        setOnLoad(true);
        // send to server to change user data
        // on error, display error
        // on success, display success message, reload page?
        unsubscribeFromService(userId.current, "FinancialDashboard").then((resp: BackendPlaidResponse)=>{
            if(resp.status === 200) {
                setSelectedIndex(1);
            } else {
                setErrorMessageModalText(resp.err)
                setShowErrorModal(true);
            }
        }).finally(()=>{
            setOnLoad(false);
        });
    }

    return (
        <>
            <MyModal isShown={onLoad} setIsShown={setOnLoad} hasExitButton={false} children={<div><h2>Loading...</h2><p>Please do not refresh the page.</p><LoadingIcon /></div>} />
            <MessageModal isShown={showMessageModal} setIsShown={setShowMessageModal} message={messageModalJSX} />
            <ErrorMessageModal isShown={showErrorModal} setIsShown={()=>{setShowErrorModal(false); setErrorMessageModalText("")}} messages={[errorMessageModalText]}/>
            <ErrorMessageModal isShown={showPaymentErrorModal} setIsShown={()=>{setShowPaymentErrorModal(false); setErrorMessageModalText("")}} messages={["Payment failed processing.", errorMessageModalText]} />
            {incomingIndex === 0 ? 
                <ConfirmationModal isShown={showEnableModal} setIsShown={setShowEnableModal} confirmCB={onAccept} rejectCB={onReject} children={[
                    <h2 key={"title"}>Subscribe to Track Your Finances</h2>,
                    (<div style={{maxHeight: "20vh", overflow: "hidden"}} key={"images"}>
                        <img key={"img1"} src="./assets/dashboard_screenshots/lower.webp" alt="" style={{width: "50%", objectFit: "scale-down", borderRadius: "2rem"}}></img>
                        <img key={"img2"} src="./assets/dashboard_screenshots/upper.webp" alt="" style={{width: "50%", objectFit: "scale-down", borderRadius: "2rem"}}></img>
                    </div>),
                    <h4 key={"p0"} style={{textAlign: "left", margin: 0, padding: 0}}>You will be billed ${calcMonthlyDashboardCost()} per month ($0 if you have a loan through Crowdcash)</h4>,
                    <p key={"p1"} style={{textAlign: "left"}}>Each connected account costs ${DASHBOARD_MONTHLY_PRICE.toFixed(2)} per month and is billed when the service is started, then on the first of each month.</p>,
                    <p key={"p2"} style={{textAlign: "left"}}>The amount will be billed to the deposit/withdraw account selected in the "Linked Bank(s)" section.</p>,
                    <p key={"p3"} style={{textAlign: "left"}}>Connecting or removing linked accounts will result in the monthly subscription cost increasing and decreasing accordingly.</p>,
                    <p key={"p4"} style={{textAlign: "left"}}>Connecting a new account will immediately charge your selected deposit/withdraw account.</p>,
                    <p key={"p5"} style={{textAlign: "left"}}>Removing an account will reduce the subscription cost and will be reflected in the next billing period.</p>,
                    <h6 key={"h6"} >By subscribing, you agree to our <span onClick={()=>navigate("/terms_of_use")} style={{cursor: "pointer", textDecoration: "underline"}}>terms of service</span> and <span onClick={()=>navigate("/privacy_policy")} style={{cursor: "pointer", textDecoration: "underline"}}>privacy policy</span></h6>
                ]}/>
            :
                <ConfirmationModal isShown={showEnableModal} setIsShown={setShowEnableModal} confirmCB={onUnsubscribe} rejectCB={()=>{setShowEnableModal(false)}} children={[
                    <h2 key={"h2"}>Unsubscribe From Financial Tracking?</h2>,
                    <p key={"p7"} style={{textAlign: "left"}}>You will lose access to the dashboard and no longer be able to see any information related to your linked bank account's transaction history.</p>,
                    <p key={"p8"} style={{textAlign: "left"}}>This will take effect on the next billing cylce.</p>,
                ]}/>
            }
            <div className={styles.EnableDashboard}>
                <h3>Enable Financial Dashboard?</h3>
                <ToggleSwitch selectedIndex={selectedIndex} initialOptIndex={selectedIndex} toggleCB={toggled} options={["Yes", "No"]} />
                {selectedIndex === 0 ? <p>${calcMonthlyDashboardCost()}/month</p> : null}
            </div>
        </>
    );
}