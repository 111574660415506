import { useEffect, useState } from "react";
import styles from "./marketplace_view.module.css"
import { SelectPrimary } from "../../../components/select/select";
import FilterView, { FilterValues } from "../filter_view/filter_view";
import { ButtonSecondary } from "../../../components/button/button";
import { LoanCalc } from "../../../helpers/loan";
import { ErrorMessageModal, MessageModal } from "../../../components/my_modal/my_modal";
import { BorrowerEstimatedLoanDetails, fetchLoansOnMarket } from "../../../helpers/api";
import { getUserCookieData } from "../../../helpers/user";

const MarketPlaceLoan = ({ loan }: { loan: BorrowerEstimatedLoanDetails }) => {
    const [isShowMoreInfo, setIsShowMoreInfo] = useState(false);

    const Message = () => {
        return (
            <>
                <h3>Additional Info Coming Soon</h3>

                <div className={styles.MarketPlaceLoanPrimaryInfoContainer}>
                    <p>Loan Amount: ${(loan.Amount / LoanCalc.ScaleFactor) + (loan.LoanDetails.OriginationFee / LoanCalc.ScaleFactor)}</p>
                    <p>Monthly Payment: ${loan.LoanDetails.MonthlyPayment / LoanCalc.ScaleFactor}</p>
                    <p>Yield: {loan.LoanDetails.Apy / LoanCalc.ScaleFactor}%</p>
                </div>

                <div className={styles.MarketPlaceLoanPrimaryInfoContainer}>
                    <p>Purpose: {loan.Purpose}</p>
                    <p>Term Length: {loan.LoanDetails.Term} Months</p>
                    <p>Credit Score: {loan.EstCredit}</p>
                </div>

                <div className={styles.MarketPlaceLoanPrimaryInfoContainer}>
                    <p>Borrower Rate: TDB</p>
                    <p>Debt to Income: TDB</p>
                    <p>Income Range: TDB</p>
                </div>

                <div className={styles.MarketPlaceLoanPrimaryInfoContainer}>
                    <p>Employment Status: TDB</p>
                    <p>Occupation: TDB</p>
                    <p>State: Texas</p>
                </div>

                <div className={styles.MarketPlaceLoanPrimaryInfoContainer}>
                    <p>Open Lines of Credit: TDB</p>
                    <p>Deliquencies: TDB</p>
                    <p>Expires: TBD</p>
                </div>
                
                <div className={styles.MarketPlaceLoanMiscInfoContainer}>
                    <p>Id: {loan.Id}</p>
                    <p>Submitted: {new Date(loan.EstAt).toLocaleString()}</p>
                </div>
            </>
        );
    }

    return (
        <div onClick={()=>setIsShowMoreInfo(!isShowMoreInfo)} className={styles.MarketPlaceLoanContainer}>
            <MessageModal isShown={isShowMoreInfo} setIsShown={setIsShowMoreInfo} message={<Message />}/>
            <div className={styles.MarketPlaceLoanPrimaryInfoContainer}>
                <p>Loan Amount: ${((loan.Amount / LoanCalc.ScaleFactor) + (loan.LoanDetails.OriginationFee / LoanCalc.ScaleFactor)).toFixed(2)}</p>
                <p>Monthly Payment: ${(loan.LoanDetails.MonthlyPayment / LoanCalc.ScaleFactor).toFixed(2)}</p>
                <p>Yield: {(loan.LoanDetails.Apy / LoanCalc.ScaleFactor).toFixed(2)}%</p>
            </div>

            <div className={styles.MarketPlaceLoanPrimaryInfoContainer}>
                <p>Purpose: {loan.Purpose}</p>
                <p>Term Length: {loan.LoanDetails.Term} Months</p>
                <p>Credit Score: {loan.EstCredit}</p>
            </div>
            
            <div className={styles.MarketPlaceLoanMiscInfoContainer}>
                <p>Id: {loan.Id}</p>
                <p>Submitted: {new Date(loan.EstAt).toLocaleString()}</p>
            </div>
        </div>
    );
}

const MarketPlaceView = () => {
    const [showErrMsg, setShowErrMsg] = useState(false);
    const [errMsg, setErrMsg] = useState<string[]>([]);
    const [showFilter, setShowFilter] = useState(false);
    const [lastRefresh, setLastRefresh] = useState<string>("");
    const [loans, setLoans] = useState<BorrowerEstimatedLoanDetails[]>([]);
    const [filter, setFilter] = useState<FilterValues>({
        Amount: { Min: 250, Max: 5000 },
        RiskRating: { Min: 0, Max: 850 },
        MonthlyPayment: { Min: 50, Max: 10_000 },
        TermLength: { Min: 1, Max: 24 },
        Yield: { Min: 0, Max: 100 },
    });
    const [filteredLoans, setFilteredLoans] = useState<BorrowerEstimatedLoanDetails[]>([]);
    const [sortOption, setSortOption] = useState<string>("Date ↓");

    const getLoans = () => {
        fetchLoansOnMarket(getUserCookieData()!.id).then((resp) => {
            setLastRefresh(new Date().toLocaleTimeString());
            setLoans(resp);
        }).catch((error)=>{
            console.log(error)
            setErrMsg([error.message]);
            setShowErrMsg(true);
        });
    }

    useEffect(() => {
        if(getUserCookieData()) {
            getLoans();
            
            const interval = setInterval(() => {
                getLoans();
            }, 15 * 60 * 1000); // 15 minutes in milliseconds

            return () => clearInterval(interval); // Clear interval on component unmount
        }
    }, []);

    // filter and Sort loans when the filtered loans or sort option changes
    useEffect(() => {
        const applyFilters = (loan: BorrowerEstimatedLoanDetails) => {
            const { Amount, RiskRating, MonthlyPayment, TermLength, Yield } = filter;
            const { Amount: loanAmount, EstCredit, LoanDetails } = loan;

            return (
                loanAmount / LoanCalc.ScaleFactor >= Amount.Min && loanAmount / LoanCalc.ScaleFactor <= Amount.Max &&
                EstCredit >= RiskRating.Min && EstCredit <= RiskRating.Max &&
                LoanDetails.MonthlyPayment / LoanCalc.ScaleFactor >= MonthlyPayment.Min && LoanDetails.MonthlyPayment / LoanCalc.ScaleFactor <= MonthlyPayment.Max &&
                LoanDetails.Term >= TermLength.Min && LoanDetails.Term <= TermLength.Max &&
                LoanDetails.Apy / LoanCalc.ScaleFactor >= Yield.Min && LoanDetails.Apy / LoanCalc.ScaleFactor <= Yield.Max
            );
        };

        const filtered = loans.filter(applyFilters);
        
        const applySort = (a: BorrowerEstimatedLoanDetails, b: BorrowerEstimatedLoanDetails) => {
            switch (sortOption) {
                case "Date ↓":
                    return new Date(b.EstAt).getTime() - new Date(a.EstAt).getTime();
                case "Date ↑":
                    return new Date(a.EstAt).getTime() - new Date(b.EstAt).getTime();
                case "Credit Score ↓":
                    return b.EstCredit - a.EstCredit;
                case "Credit Score ↑":
                    return a.EstCredit - b.EstCredit;
                case "Monthly Payment ↓":
                    return b.LoanDetails.MonthlyPayment - a.LoanDetails.MonthlyPayment;
                case "Monthly Payment ↑":
                    return a.LoanDetails.MonthlyPayment - b.LoanDetails.MonthlyPayment;
                case "Amount ↓":
                    return b.Amount - a.Amount;
                case "Amount ↑":
                    return a.Amount - b.Amount;
                case "Term ↓":
                    return b.LoanDetails.Term - a.LoanDetails.Term;
                case "Term ↑":
                    return a.LoanDetails.Term - b.LoanDetails.Term;
                case "Yield ↓":
                    return b.LoanDetails.Apy - a.LoanDetails.Apy;
                case "Yield ↑":
                    return a.LoanDetails.Apy - b.LoanDetails.Apy;
                default:
                    return 0;
            }
        };

        const sortedLoans = [...filtered].sort(applySort);
        setFilteredLoans(sortedLoans);
    }, [filter, loans, sortOption]);

    const onSortChange = (e: any) => {
        setSortOption(e.target.value);
    }

    return (
        <div className={styles.MarketContainer}>
            <ErrorMessageModal isShown={showErrMsg} setIsShown={setShowErrMsg} messages={errMsg}/>
            <h1>Market</h1>
            {/* filter &  sort */}
            <div style={{width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                <div style={{position: 'relative', display: 'flex', flexDirection: 'column', width: '80%', margin: 'auto'}}
                    onMouseLeave={()=>{setShowFilter(false)}}
                >
                    <ButtonSecondary 
                        // onClick={()=>{setShowFilter(!showFilter)}}
                        onMouseEnter={()=>{setShowFilter(!showFilter)}}
                    > ⧖ </ButtonSecondary>
                    {showFilter ? <FilterView filterSettings={filter} onFilterChange={setFilter}/> : null}
                </div>
                <div style={{width: '80%', margin: 'auto'}}>
                    <SelectPrimary onChange={onSortChange}>
                        <option value="Date ↓">Date (↓)</option>
                        <option value="Date ↑">Date (↑)</option>
                        <option value="Credit Score ↓">Credit Score (↓)</option>
                        <option value="Credit Score ↑">Credit Score (↑)</option>
                        <option value="Monthly Payment ↓">Monthly Payment (↓)</option>
                        <option value="Monthly Payment ↑">Monthly Payment (↑)</option>
                        <option value="Amount ↓">Amount (↓)</option>
                        <option value="Amount ↑">Amount (↑)</option>
                        <option value="Term ↓">Term (↓)</option>
                        <option value="Term ↑">Term (↑)</option>
                        <option value="Yield ↓">Yield (↓)</option>
                        <option value="Yield ↑">Yield (↑)</option>
                    </SelectPrimary>
                </div>
            </div>
            {/* filtered loans */}
            <div className={styles.AllMarketPlaceLoanContainer}>
                {filteredLoans.length >= 1 ? filteredLoans.map((loan, idx)=>{
                    return <MarketPlaceLoan key={idx} loan={loan} />
                }) : <p>No Matching Loans</p>}
            </div>
            <div className={styles.MarketContainerSubtitleContainer}>
                <p>Found {loans.length} Loans</p>
                <p>Filtered to {filteredLoans.length} Loans</p>
                <p>Last Fetched: {lastRefresh}</p>
            </div>
        </div>
    );
}

export default MarketPlaceView;