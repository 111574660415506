import styles from './account.module.css'
import React, { useEffect, useState } from "react";
import { SignUpInModal } from '../../components/signin_up_modal/signin_up_modal.tsx';
import { User, getUserCookieData, isUserSignedIn, saveUserData, UserImpl, getServerUserData, PlaidLinkedBank } from '../../helpers/user.ts';
import { isStateSupported } from '../../helpers/api.ts';
import { sleep } from '../../helpers/sleep.ts';
import BankManagement from '../../components/BankManagement/BankManagement.tsx';
import { InputSecondary } from '../../components/input/input.tsx';
import { ButtonPrimary } from '../../components/button/button.tsx';
import { EnableDashboard } from './enable_dashboard/enable_dashboard.tsx';
import { useLoadedAnalytics } from '../../helpers/analytics.tsx';

// TODO: delete account?
// TODO: change password?
const AccountManagement = () => {
    useLoadedAnalytics("account");
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [userData, setUserData] = useState<User>(new UserImpl());

    const signInComplete = () => {
        setShowSignInModal(false)
        setUserData(getUserCookieData()!);
    }

    const handleChange = (e: { target: { name: string; value: any; }; }) => {
        const { name, value } = e.target;
    
        // Special case: handle zip change
        if (name === "zip") {
            if (value.length === 5) {
                isStateSupported(value).then(res => {
                    setUserData((prevUserData) => ({
                        ...prevUserData,
                        bio: {
                            ...prevUserData.bio,
                            state: res.State,
                            zip: value,
                        }
                    }));
                });
            } else {
                // Update only zip in bio if length is not 5
                setUserData((prevUserData) => ({
                    ...prevUserData,
                    bio: {
                        ...prevUserData.bio,
                        zip: value,
                    }
                }));
            }
        } else {
            // General case: update other fields in bio
            setUserData((prevUserData) => ({
                ...prevUserData,
                bio: {
                    ...prevUserData.bio,
                    [name]: value,
                }
            }));
        }
    };

    const handleBankManagementChange = (changedPlaidLinkedBanks: PlaidLinkedBank[]) => {
        setUserData({...userData, plaidLinkedBanks:changedPlaidLinkedBanks});
    }

    const handleSave = async () => {
        setIsSaving(true);
        console.log(userData)
        await saveUserData(userData);
        await sleep(100);
        setUserData(await getServerUserData(userData.email, userData.id))
        await sleep(100);
        setIsSaving(false);
    };

    useEffect(()=>{
        if(!isUserSignedIn()) {
            setShowSignInModal(true)
        } else {
            const _userData = getUserCookieData()!
            // TOOD: if any of the location data has changed, identity verification may be needed again
            setUserData(_userData)
        }
    }, [])

    return (
        <div className={styles.AccountManagementContainer}>
            {showSignInModal ? 
                <SignUpInModal showModal={true} modalEscapeCB={undefined} processCompleteHook={signInComplete} /> 
                : 
                <div className={styles.DataInputContainer}>
                    <EnableDashboard />
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>First Name</label>
                        <InputSecondary type='text' name='firstName' value={userData.bio.firstName} onChange={handleChange} />
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Last Name</label>
                        <InputSecondary type='text' name='lastName' value={userData.bio.lastName} onChange={handleChange} />
                    </div>
                    {/* <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Date of Birth</label>
                        <InputSecondary type='date' name='dob' value={userData.dob} onChange={handleChange} />
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Email</label>
                        <InputSecondary type='email' name='email' value={userData.email} onChange={handleChange} />
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Phone</label>
                        <InputSecondary type='text' name='phone' value={userData.phone} onChange={handleChange} />
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Address</label>
                        <InputSecondary type='text' name='address' value={userData.address} onChange={handleChange} />
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Type of Residence</label>
                        <SelectSecondary value={userData.typeOfResidence} name='typeOfResidence' onChange={handleChange}>
                            <option value="" disabled hidden></option>
                            <option value="Own">Own</option>
                            <option value="Rent">Rent</option>
                        </SelectSecondary>
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Zip</label>
                        <InputSecondary type='text' name='zip' value={userData.zip} onChange={handleChange} />
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>State</label>
                        <InputSecondary type='text' name='state' value={userData.state} readOnly/>
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Employment Status</label>
                        <SelectSecondary value={userData.employmentStatus} name='employmentStatus' onChange={handleChange}>
                            <option value="" disabled hidden></option>
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                            <option value="Self-Employed">Self-Employed</option>
                            <option value="Unemployed">Unemployed</option>
                            <option value="Other">Other</option>
                        </SelectSecondary>
                    </div>
                    <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>Income Before Taxes</label>
                        <InputSecondary type='number' name='incomeBeforeTaxes' value={userData.incomeBeforeTaxes} onChange={handleChange} />
                    </div> */}
                    <div className={styles.BankManagementContainer}>
                        <BankManagement onDataChange={handleBankManagementChange} />
                    </div>
                    {/* <div className={styles.DataInputSection}>
                        <label className={styles.DataInputTitle}>NMLS ID</label>
                        <input className='DataInput' type='text' name='nmlsId' value={userData.nmlsId} onChange={handleChange} />
                    </div> */}
                    {/* <label className={styles.DataInputTitle}>Lenders Licenses</label> */}
                    {/* <button className='DataButton' onClick={addLicense}>Add License</button> */}
                    {/* <div className='AllLendersLicenseContainer'>
                        TODO: show if license if validated
                        {userData.lendersLicenses ? userData.lendersLicenses.map((license, index) => (
                            <div className='LicenseDataContainer' key={index}>
                                <select className='LicenseDataInput' name='state' value={license.state} onChange={(e) => handleLicenseChange(e, index)}>
                                    <option value="" disabled>Select a state</option>
                                    {US_STATES.map((state, index) => (
                                        <option key={index} value={state}>{state}</option>
                                    ))}
                                </select>
                                <input className='LicenseDataInput' type='text' name='lendersLicense' value={license.lendersLicense} onChange={(e) => handleLicenseChange(e, index)} placeholder="License" />
                                <button className='DataLicenseButton' onClick={() => removeLicense(index)}>Remove</button>
                            </div>
                        )) : null}
                    </div> */}
                    <ButtonPrimary onClick={handleSave} disabled={isSaving}>{isSaving ? "..." : "Save"}</ButtonPrimary>
                </div>
            }
        </div>
    );
}

export default AccountManagement;